@font-face {
  font-family: "Source Sans Pro Regular";
  src: url("../fonts/SourceSansPro/SourceSansProRegular/SourceSansProRegular.ttf") format("truetype"); }

@font-face {
  font-family: "Source Sans Pro Bold";
  src: url("../fonts/SourceSansPro/SourceSansProBold/SourceSansProBold.ttf") format("truetype"); }

@font-face {
  font-family: "Source Sans Pro Light";
  src: url("../fonts/SourceSansPro/SourceSansProLight/SourceSansProLight.ttf") format("truetype"); }

/* top */
#top #top-left-links ul li a,
#top #top-left-links ul li a:visited {
  border: transparent; }

#top #top-left-links ul li a:hover,
#top #top-right-links > ul > li:hover {
  border-bottom: transparent; }

/* language */
button.btn.btn-link.dropdown-toggle.btn-language {
  margin-bottom: 3px; }

#top .btn-link.btn-language i:first-child,
#top .btn-link.btn-currency i:first-child {
  top: 0;
  font-size: 14px; }

#top .btn-link.btn-language i:first-child {
  font-size: 12px; }

button.btn.btn-link.dropdown-toggle.btn-language span {
  font-size: 14px; }

.btn-language > span {
  margin-right: 4px; }

.list-inline a span {
  font-size: 14px; }

/* header */
header {
  padding: 17px 0 8px;
  background: #222 !important; }

/* logo */
#logo {
  margin: 0; }

/* search */
.ts-search-box {
  align-self: flex-start;
  margin-top: 0; }

#search .cats-button {
  display: none; }

.search-slogan {
  font-size: 14px;
  color: #93999d;
  text-transform: uppercase;
  text-align: center; }
  .search-slogan > span:first-child {
    color: #3498db; }
  .search-slogan > span:nth-child(2) {
    color: #fff; }

button#oct-search-button {
  padding: 10px 16px !important;
  background: #3498db !important; }
  button#oct-search-button i {
    color: #fff !important; }
  button#oct-search-button:hover {
    background-size: #006BB2 !important; }

/* phones */
.phones-top-box {
  margin-bottom: 20px; }

.phones-top-box-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: center; }
  .phones-top-box-list i {
    font-size: 15px;
    margin-right: 8px;
    color: #fff; }

.phones-dropdown a i.fa-phone {
  font-size: 19px; }

.dropdown.phones-dropdown > a > span {
  color: #3498db; }

.phones-dropdown a i.fa-caret-down {
  color: #3498db; }

.phones-dropdown a.show-phones {
  top: 3px;
  left: 31px; }

/* cart */
.buttons-top-box {
  margin-bottom: -2px; }

#cart i span {
  background: #3498db; }

#cart:hover {
  background: #006BB2; }
  #cart:hover i span {
    background: #006BB2; }

#menu .nav > li > a {
  font-family: "Source Sans Pro Bold", sans-serif;
  font-size: 16px; }

#oct-bluring-box .menu-row {
  background: #3498db; }

#menu .dropdown-inner a {
  font-size: 14px; }

#menu .see-all {
  color: #3498db !important; }

.menu-row.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  animation: smoothScroll .25s ease; }

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px); }
  100% {
    transform: translateY(0px); } }

.common-home .oct-slideshow-box {
  padding: 0;
  margin: 0 !important; }
  .common-home .oct-slideshow-box .owl-wrapper-outer {
    padding: 0; }
  .common-home .oct-slideshow-box .item {
    padding: 0; }

.common-home h2 {
  text-align: center;
  font-family: 'OpenSansRegular';
  text-transform: uppercase;
  font-size: 24px;
  color: #3498db;
  margin-top: 20px;
  margin-bottom: 30px;
  position: relative; }

.oct-category-item-box {
  padding: 22px 15px;
  margin-bottom: 24px;
  height: auto;
  min-height: 320px; }
  .oct-category-item-box .oct-category-item-text {
    font-size: 17px;
    min-height: 49px;
    text-align: center; }

.main-advantage-item-button {
  text-align: center; }
  .main-advantage-item-button a {
    display: inline-block;
    margin: 0 auto;
    padding: 6px 24px 6px;
    text-align: center;
    background: #3498db;
    color: #fff; }

#sstore-3-level ul:first-child li.has-sub > a.toggle-a:before,
#sstore-3-level ul:first-child li.has-sub > a.toggle-a:after {
  background: #fff; }

#sstore-3-level ul > li.has-sub.open > a.toggle-a:after,
#sstore-3-level ul > li.has-sub.open > a.toggle-a:before {
  background: #fff !important; }

.product-grid .product-thumb {
  text-align: center;
  padding: 0 0 12px; }

.product-grid .product-thumb .cart a {
  display: block; }
  .product-grid .product-thumb .cart a:hover {
    background: #006BB2 !important;
    color: #fff !important; }

.product-grid .product-thumb:hover .oct-product-desc {
  display: none; }

/* hover effect */
.product-grid .product-thumb .cat-box-effect {
  height: 80px; }

.product-thumb .cat-model {
  margin-top: 6px; }

.product-thumb .oct-product-stock {
  display: none; }

.product-thumb h4 a {
  font-size: 15px;
  font-family: "Source Sans Pro Regular", sans-serif;
  color: #000 !important; }

/* additional hover image */
.product-grid .image .hover-image,
.product-list .image .hover-image {
  transition: all .6s ease-in-out;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  left: 0;
  right: 0;
  position: absolute;
  top: 0; }

.product-grid .image:hover .hover-image,
.product-list .image:hover .hover-image {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
  transition: all .6s ease; }

.after-header-item a {
  color: #3498db; }

a.oct-button.button-one-click {
  width: 50%; }

a.oct-button.button-wishlist,
a.oct-button.button-compare {
  display: none; }

span[itemprop="brand"] {
  color: #333 !important; }

/* kit series */
#kjseries {
  overflow-x: auto; }
  #kjseries thead {
    background: #fff !important; }
  #kjseries .kj-item {
    background: #fff !important; }
  #kjseries .btn_buy_text button {
    background: #3498db;
    border: none;
    outline: none; }
    #kjseries .btn_buy_text button:hover {
      background: #006BB2; }
    #kjseries .btn_buy_text button i {
      margin-right: 2px; }

/* fancy box */
.fancybox-controls.bottom {
  top: 50%; }

.fancybox-infobar__body {
  display: none;
  position: relative;
  top: 250px; }

button.fancybox-button.fancybox-button--left {
  right: 150%;
  opacity: .85;
  border-radius: 50%; }

button.fancybox-button.fancybox-button--right {
  left: 150%;
  opacity: .85;
  border-radius: 50%; }

.account-address-add .form-group.custom-field {
  display: none; }

body {
  font-family: 'Source Sans Pro Regular', sans-serif; }

.row.sort-row {
  background: transparent; }

a.wishlist.oct-button.current-link,
a.compare.oct-button.current-link {
  display: none !important; }

a.wishlist.oct-button,
a.compare.oct-button {
  display: none !important; }

#currency {
  display: none; }

.oct-carousel-row .cart a {
  display: block;
  width: 80%; }

@media screen and (max-width: 992px) {
  /* header */
  header {
    padding: 79px 0 10px; }
  #m-wishlist,
  #m-compare {
    display: none; }
  /* menu */
  #menu .nav > li > a {
    color: #333 !important; } }

@media screen and (min-width: 1024px) {
  /* hover effect */
  .product-grid .product-thumb:hover .cat-box-effect-inner {
    transform: unset !important; }
  .product-grid .product-thumb:hover .oct-product-stock {
    display: none; } }

@media screen and (min-width: 1200px) {
  .dropdown.phones-dropdown {
    margin-left: 15px; } }
